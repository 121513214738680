import Vue from "vue";
import App from "./App.vue";
import { MediaQueries } from "vue-media-queries";
import VueYandexMetrika from "vue-yandex-metrika";
import VueGeolocation from "vue-browser-geolocation";
import VueAnalytics from "vue-analytics";
import vueBemCn from "vue-bem-cn";
import "./registerServiceWorker";
import notifier from "./plugins/notifier";
import drawer from "./plugins/drawer";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import fontsLoader from "./plugins/fontsLoader";
Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
Vue.use(VueGeolocation);

Vue.use(VueYandexMetrika, {
  id: 64731667,
  router: router,
  env: process.env.NODE_ENV,
});

Vue.use(VueAnalytics, {
  id: "UA-168939055-1",
});

const bemConfig = {
  delimiters: {
    ns: "", // namespace
    el: "__", // element delimeter
    mod: "_", // modifier delimeter
    modVal: "_", // value delimeter for modifier
  },
};
Vue.use(vueBemCn, bemConfig);
Vue.use(notifier);
Vue.use(drawer);
Vue.use(fontsLoader);

new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
  mediaQueries,
}).$mount("#app");
