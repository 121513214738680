<template>
  <section class="nav-menu">
    <div class="nav-menu__item">
      <v-btn small text color="primary">
        <router-link
          to="/"
          exact-active-class="exact-active"
          class="d-flex justify-center align-center"
        >
          <img class="pr-2" src="~icons/home-blue.svg" alt="" />
          <span>Главная</span>
        </router-link>
      </v-btn>
    </div>
<!--    <div class="nav-menu__item">-->
<!--      <v-btn small text color="primary">-->
<!--        <router-link-->
<!--          to="/blog"-->
<!--          exact-active-class="exact-active"-->
<!--          class="d-flex justify-center align-center"-->
<!--        >-->
<!--          <img class="pr-2" src="~icons/blog.svg" alt="" />-->
<!--          <span>Новости</span>-->
<!--        </router-link>-->
<!--      </v-btn>-->
<!--    </div>-->
    <div class="nav-menu__item">
      <v-btn small text color="primary">
        <router-link
          to="/help"
          exact-active-class="exact-active"
          class="d-flex justify-center align-center"
        >
          <img class="pr-2" src="~icons/information.svg" alt="" />
          <span>Помощь</span>
        </router-link>
      </v-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavMenuComponent",
}
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";
.nav-menu {
  font-family: $sanspro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.03em;

  .active {
    border-bottom: 1px solid $light-blue;
  }
}
.nav-menu__item {
  display: inline-block;
  padding: 2px 2px;
}
a {
  &:hover {
    color: inherit !important;
  }
  img {
    width: 22px;
    height: 22px;
  }
}
.exact-active {
  background: $primary-color;
  margin: -3px -12px;
  padding: 3px 11px;
  border-radius: 4px;
}
</style>
