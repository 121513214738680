<template>
  <div class="footer-menu">
    <ul class="footer__nav">
      <li>
        <router-link to="/about"> О нас </router-link>
      </li>
      <li>
        <router-link to="/help"> Помощь </router-link>
      </li>
      <li>
        <router-link to="/clinic-landing"> Сотрудничество </router-link>
      </li>
      <li>
        <a href="https://old.medveddoc.com/docs/personal_data_processing.pdf">
          Обработка персональных данных
        </a>
      </li>
      <li>
        <a href="https://old.medveddoc.com/docs/personal_data_protection.pdf">
          Положение о защите персональных данных
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>

<style lang="scss" scoped>
.footer__nav {
  padding: 0;
  font-size: 14px;
  list-style-type: none;

  li {
    margin-bottom: 10px;

    a {
      @include link-light($light-color, $primary-lighter-color);
    }
  }
}
</style>
