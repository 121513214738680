/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект с результатами поиска*/
  [names.MUTATE_SAVE_OBJ_SEARCH]: (state, payload) => {
    state.searchObj = payload;
  },

  /*Очищает объект с результатами поиска*/
  [names.MUTATE_CLEAR_OBJ_SEARCH]: (state) => {
    state.searchObj = [];
  },

  /*Сохраняет объект с результатами поиска (Услуги)*/
  [names.MUTATE_SAVE_OBJ_SEARCH_SERVICE]: (state, payload) => {
    state.searchServiceObj = payload;
  },

  /*Сохраняет объект с результатами поиска (Услуги)*/
  [names.MUTATE_SAVE_OBJ_SEARCH_SERVICE_PAGINATION]: (state, payload) => {
    state.searchServicePagination = payload;
  },

  /*Сохраняет объект с результатами поиска (Клиники)*/
  [names.MUTATE_SAVE_OBJ_SEARCH_CLINIC]: (state, payload) => {
    state.searchClinicObj = payload;
  },

  /*Сохраняет объект врачей с первичными приемами во всех филиалах*/
  [names.MUTATE_SAVE_OBJ_SERVICE_BY]: (state, payload) => {
    state.objServiceBy = payload.data.dataObj;
    state.objPaginationSearch = payload.data.pagination;
    state.objSortValuesSearch = payload.data.sortValues;
    state.objTypeValuesSearch = payload.data.typeValues;
    state.availableFilters = payload.data.availableFilters;
    state.objSpecializationNames = payload.data.specializationNames;
    if (payload.update === false) {
      state.maxPriceSearch = payload.data.maxPrice;
      state.minPriceSearch = payload.data.minPrice;
    }
  },

  /*Сохраняет значения для сортировки*/
  [names.MUTATE_SAVE_SELECTED_SORT_VALUES]: (state, payload) => {
    state.selectedSortValue = payload.sortValue;
    state.selectedTypeValue = payload.typeValue;
    state.selectedPrice = payload.price;
    state.selectedCityParts = payload.cityParts;
    state.selectedMyCoordinatesLat = payload.MyCoordinatesLat;
    state.selectedMyCoordinatesLong = payload.MyCoordinatesLong;
  },

  /*Очищает объект врачей с первичными приемами во всех филиалах*/
  [names.MUTATE_CLEAR_OBJ_SERVICE_BY]: (state) => {
    state.objServiceBy = [];
    state.selectedPrice = [];
    state.selectedDoctorInfo = [];
    state.objPaginationSearch = [];
    state.objSortValuesSearch = [];
    state.availableFilters = [];
    state.selectedCityParts = null;
    state.selectedTypeValue = null;
    state.selectedSortValue = "price_asc";
    state.selectedMyCoordinatesLat = null;
    state.selectedMyCoordinatesLong = null;
    state.mapAddress = "";
  },

  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_SEARCH_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  /*Сохраняет объект с подробным результатом услуг о докторе и краткой  информацией*/
  [names.MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL]: (state, payload) => {
    state.objDoctorDetail = payload.services;
    state.objDoctorDetailInfo = payload.doctor;
  },

  [names.MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION]: (state, payload) => {
    state.objDoctorDetailPagination = payload;
  },

  /*Очищает объект с подробным результатом услуг о докторе и краткой  информацией*/
  [names.MUTATE_CLEAR_OBJ_SEARCH_DOCTOR_DETAIL]: (state) => {
    state.objDoctorDetail = [];
    state.objDoctorDetailInfo = [];
  },

  /*Сохраняет объект со списком врачей, оказывающих определенную услугу*/
  [names.MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL]: (state, payload) => {
    state.objServiceDetail = payload;
  },
  /*Сохраняет объект со списком врачей, оказывающих определенную услугу*/
  [names.MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL_PAGINATION]: (
    state,
    payload
  ) => {
    state.objServiceDetailPagination = payload;
  },

  [names.MUTATE_CLEAR_OBJ_SEARCH_SERVICE_DETAIL]: (state) => {
    state.objServiceDetail = [];
    state.selectedServiceInfo = [];
    state.selectedDoctorInfo = [];
  },

  /*Сохраняет объект с информацией о клинике*/
  [names.MUTATE_SAVE_OBJ_SEARCH_CLINIC_DETAIL]: (state, payload) => {
    state.objClinicDetail = payload;
  },

  /*Очищает объект с информацией о клинике*/
  [names.MUTATE_CLEAR_OBJ_SEARCH_CLINIC_DETAIL]: (state) => {
    state.objClinicDetail = [];
  },

  /*Сохраняет данные при поиске для передачи их в другие компоненты*/
  [names.MUTATE_TRANSMISSION_SEARCH_VALUE]: (state, payload) => {
    state.searchValue = payload;
  },

  /*Сохраняет тип поиска для передачи его в другие компоненты*/
  [names.MUTATE_TRANSMISSION_SEARCH_TYPE]: (state, payload) => {
    state.searchType = payload;
  },

  /*Сохраняет текст в инпуте для передачи его в другие компоненты*/
  [names.MUTATE_TRANSMISSION_SEARCH_PLACEHOLDER]: (state, payload) => {
    state.searchPlaceholder = payload;
  },

  /*Сохраняет ID филиала*/
  [names.MUTATE_SAVE_DEPARTMENT_ID]: (state, payload) => {
    state.departmentId = payload;
  },

  /*Сохраняет инфу о выбранном враче для быстрой записи*/
  [names.MUTATE_SAVE_SELECTED_DOCTOR_INFO]: (state, payload) => {
    state.selectedDoctorInfo = payload;
  },
  /*Сохраняет инфу о выбранной услуге для быстрой записи*/
  [names.MUTATE_SAVE_SELECTED_SERVICE_INFO]: (state, payload) => {
    state.selectedServiceInfo = payload;
  },
};
export { mutations };
