import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import loader from "../plugins/loader";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  /*Главная страница*/
  {
    path: "/",
    name: "TheHomePage",
    component: () => import("../views/TheHomePage.vue"),
  },

  /*Главная страница*/
  {
    path: "/search/:city/:entity/:id",
    name: "TheHomePageDynamic",
    props: true,
    component: () => import("../views/TheHomePage.vue"),
  },

  /*Страница новостей*/
  {
    path: "/blog",
    name: "TheNewsPage",
    component: () => import("../views/TheNewsPage.vue"),
  },

  /*Страница конкретной новости*/
  {
    path: "/blog/:url",
    name: "CurrentNewsComponent",
    props: true,
    component: () => import("../components/news/CurrentNewsComponent"),
  },

  /*Страница помощи*/
  {
    path: "/help",
    name: "TheHelpPage",
    component: () => import("../views/TheHelpPage"),
  },

  /*Страница о нас*/
  {
    path: "/about",
    name: "TheAboutPage",
    component: () => import("../views/TheAboutPage"),
  },

  /*Страница сотрудничество*/
  {
    path: "/clinic-landing",
    name: "TheCooperationPage",
    component: () => import("../views/TheCooperationPage"),
  },

  /*Страница корзины*/
  {
    path: "/basket",
    name: "TheBasketPage",
    component: () => import("../views/TheBasketPage"),
  },

  /*Страница 404*/
  {
    path: "*",
    name: "The404Page",
    component: () => import("../views/The404Page.vue"),
  },

  /*Страница клиники + Дочерние компоненты*/
  {
    path: "/department/:id",
    component: () =>
      import("../components/department-card/DepartmentCardComponent.vue"),
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: "doctors",
        name: "Doctors",
        component: () =>
          import(
            "../components/department-card/templates/DepartmentCardDoctors"
          ),
        props: true,
      },
      {
        path: "service",
        name: "Service",
        component: () =>
          import(
            "../components/department-card/templates/DepartmentCardService"
          ),
        props: true,
      },
      {
        path: "gallery",
        name: "Gallery",
        component: () =>
          import(
            "../components/department-card/templates/DepartmentCardGallery"
          ),
        props: true,
      },
      {
        path: "feedback",
        name: "DepartmentFeedback",
        component: () =>
          import(
            "../components/department-card/templates/DepartmentCardFeedback"
          ),
        props: true,
      },
    ],
  },

  // Страница доктора + Дочерние компоненты
  {
    path: "/doctor/:id",
    component: () => import("../components/doctor-card/DoctorCardComponent"),
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: "services",
        name: "Services",
        component: () =>
          import("../components/doctor-card/templates/DoctorCardServices"),
        props: true,
      },
      {
        path: "education",
        name: "Education",
        component: () =>
          import("../components/doctor-card/templates/DoctorCardEducation"),
        props: true,
      },
      {
        path: "feedback",
        name: "DoctorFeedback",
        component: () =>
          import("../components/doctor-card/templates/DoctorCardFeedback"),
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    loader.preloader(true, "primary", 64, 1);
  }
  next();
});
router.afterEach(() => {
  setTimeout(function () {
    loader.preloader(false, "");
  }, 888);
});

export default router;
