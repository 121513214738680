<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="order-md-2 d-flex flex-column justify-content-between footer__lastcol"
          >
            <ul class="footer__nav">
              <li class="footer__social">
                <a href="/" class="link_decoration-none">
                  <img src="~images/Logo_White.svg" alt="" />
                </a>
              </li>
              <li class="footer__social">
                <p>Нужна помощь? Звоните!</p>
                <a href="tel: 8 (800) 555-11-20" class="footer__phone"
                  >8 (800) 555-11-20</a
                >
              </li>
              <li class="footer__social">
                <a
                  href="mailto:system@medveddoc.com?subject=Обратная связь с MedvedDoc"
                  class="link__light"
                  >system@medveddoc.com</a
                >
              </li>
              <li class="footer__social">
                <a href="https://twitter.com/medveddoc" target="_blank">
                  <v-icon large color="#575f73">
                    {{ icons.mdiTwitter }}
                  </v-icon>
                </a>
                <a
                  href="https://www.instagram.com/medved_doc/?igshid=1jb49s52snwnj"
                  target="_blank"
                >
                  <v-icon large color="#575f73">
                    {{ icons.mdiInstagram }}
                  </v-icon>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEyryS1r4mC5hZ-_bHszxDg"
                  target="_blank"
                >
                  <v-icon large color="#575f73">
                    {{ icons.mdiYoutube }}
                  </v-icon>
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="6">
            <footer-menu />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="footer__bottom">
              <small> © 2020 - {{ new Date().getFullYear() }}, </small>
              <small>
                Общество с ограниченной ответственностью «Медицинские
                технологии»
              </small>
              <br />
              <small class="color-grey">
                Администрация сайта не несет ответственность за недостоверность
                информации, включая стоимость указанных на сайте услуг.
              </small>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
import { mdiTwitter, mdiInstagram, mdiYoutube, mdiVk } from "@mdi/js";
import FooterMenu from "../footer/FooterMenu";

export default {
  name: "FooterComponent",
  data: () => ({
    icons: {
      mdiTwitter,
      mdiInstagram,
      mdiYoutube,
      mdiVk,
    },
  }),
  components: {
    FooterMenu,
  },
};
</script>

<style lang="scss" scoped>
.footer__lastcol {
  padding: 0 12px;
}

.footer {
  padding: 50px 0 15px;
  background-color: $grey-blue;
  color: $light-color;
  font-family: $sanspro;
  width: 100vw;
  box-sizing: border-box;
}

.footer-wrapper {
  margin: 15px auto 15px;
  max-width: 930px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__phone {
  font-size: 30px;
  line-height: 1px;
  text-align: right;
  @include link-light($light-color, $primary-lighter-color);

  &:hover {
    text-decoration: none;
  }
}

.footer__nav {
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  margin: 0;

  li {
    margin-bottom: 10px;

    a {
      @include link-light($light-color, $primary-lighter-color);
    }
  }
}

.footer__social {
  text-align: right;
  color: $light-color;
  margin-bottom: 15px;

  a {
    .v-icon {
      border-radius: 8px;
      background-color: white;
      margin-right: 6px;

      &:hover {
        background-color: $primary-lighter-color;
        transition: background-color 0.35s ease;
      }
    }
  }
  img {
    width: 256px;
    height: 40px;
  }
}

.footer__bottom {
  margin-top: 10px;
  color: $grey50;
}

@include tablet-down {
  .footer {
    padding: 0 0 40px;

    &__social {
      text-align: left;
    }

    &__phone {
      font-size: 25px;
    }
  }
}
</style>
