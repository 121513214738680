import Api from "../services/service";

class SearchService {
  api = new Api();

  /*Глобальный поиск по сфинксу для получения динамических подсказок*/
  mainSearch(payload) {
    return this.api.post("search/main-search", {
      type: payload.type,
      value: payload.value,
      cityId: payload.cityId,
    });
  }

  /*Клик по динамической подсказке. Сущность user.
      Вкладка Врач. Получить первичные приемы определенного врача
      во всех филиалах где он работает
     */
  mainSearchDoctorDepartmentFirstServiceByDoctorId(payload) {
    return this.api.post(
       "search/main-search-doctor-department-first-service-by-doctor-id?page=" + payload.page + "&per-page=30",
      {
        doctorId: payload.doctorId,
        cityId: payload.cityId,
        filterPriceStart: payload.filterPriceStart,
        filterPriceEnd: payload.filterPriceEnd,
        filterChildren: payload.filterChildren,
        cityPart: payload.cityPart,
        myCoordinatesLat: payload.myCoordinatesLat,
        myCoordinatesLong: payload.myCoordinatesLong,
        sort: payload.sort,
        page: payload.page,
      }
    );
  }

  /*Клик по динамической подсказке. Сущность specialization.
    Вкладка Врач. Получить приемы врачей по определенной
    специализации
   */
  mainSearchDoctorDepartmentServiceBySpecializationId(payload) {
    return this.api.post(
       "search/main-search-doctor-department-service-by-specialization-id?page=" + payload.page + "&per-page=30",
      {
        specializationId: payload.specializationId,
        cityId: payload.cityId,
        filterPriceStart: payload.filterPriceStart,
        filterPriceEnd: payload.filterPriceEnd,
        filterChildren: payload.filterChildren,
        filterTypeDepartment: payload.filterTypeDepartment,
        cityPart: payload.cityPart,
        myCoordinatesLat: payload.myCoordinatesLat,
        myCoordinatesLong: payload.myCoordinatesLong,
        sort: payload.sort,
        page: payload.page,
      }
    );
  }

  /*
       Открытие шторки с подробным результатом услуг о докторе и краткой его информацией
      */
  mainSearchDoctorDetail(payload) {
    return this.api.post("search/main-search-doctor-detail?page=" + payload.page + "&per-page=30", {
      doctorId: payload.doctorId,
      cityId: payload.cityId,
      groupByDepartment: payload.groupByDepartment,
      specializationId: payload.specializationId,
      departmentId: payload.departmentId,
      page: payload.page,
    });
  }

  /*
     Клик по динамической подсказке. Сущность service. Вкладка Услуга. Получить список услуг по филиалам с ценой
    */
  mainSearchService(payload) {
    return this.api.post("search/main-search-service?page=" + payload.page + "&per-page=30", {
      serviceId: payload.serviceId,
      cityId: payload.cityId,
      filterPriceStart: payload.filterPriceStart,
      filterPriceEnd: payload.filterPriceEnd,
      filterTypeDepartment: payload.filterTypeDepartment,
      cityPart: payload.cityPart,
      myCoordinatesLat: payload.myCoordinatesLat,
      myCoordinatesLong: payload.myCoordinatesLong,
      sort: payload.sort,
      page: payload.page,
    });
  }

  /*
       Открытие шторки со списком врачей, оказывающих определенную услугу
      */
  mainSearchServiceDetail(payload) {
    return this.api.post("search/main-search-service-detail?page=" + payload.page + "&per-page=30", {
      doctorId: payload.doctorId,
      clinicDepartmentServiceId: payload.clinicDepartmentServiceId,
      page: payload.page,
    });
  }

  /*
     Клик по динамической подсказке. Сущность clinic. Вкладка Клиника. Получить список филиалов клиники
    */
  mainSearchClinic(payload) {
      return this.api.post("search/main-search-clinic?page=" + payload.page + "&per-page=30", {
      clinicId: payload.clinicId,
      cityId: payload.cityId,
      cityPart: payload.cityPart,
      myCoordinates: payload.myCoordinates,
      sort: payload.sort,
      page: payload.page,
    });
  }

  /*
     Открытие шторки с подробным описанием филиала клиники
    */
  mainSearchClinicDetail(payload) {
    return this.api.post("search/main-search-clinic-detail", {
      departmentId: payload.departmentId,
    });
  }
}

export default new SearchService();
