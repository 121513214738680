<template>
  <header class="header">
    <v-container>
      <v-row>
        <v-col cols="9" sm="5">
          <div class="header__logo color-light-blue text-lowercase">
            <a class="link_decoration-none" href="/">
              <img src="~images/Logo_Blue.svg" alt="" />
              <p>Поиск врачей и клиник</p>
            </a>
          </div>
        </v-col>
        <v-col cols="3" sm="7">
          <v-row class="text-right" v-if="$resize && $mq.above(992)">
            <v-col cols="12">
              <div class="header__menu_item">
                <a @click="dialogCity">
                  <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                  {{ selectedCity.title }}
                </a>
              </div>
              <div class="header__menu_item">
                <router-link to="/about">
                  <a>
                    <v-icon small>{{ icons.mdiInformation }}</v-icon>
                    О нас
                  </a>
                </router-link>
              </div>
              <div class="header__menu_item">
                <router-link to="/clinic-landing">
                  <a>
                    <v-icon small>{{
                      icons.mdiAccountSupervisorCircle
                    }}</v-icon>
                    Сотрудничество
                  </a>
                </router-link>
              </div>
              <div class="header__menu_item">
                <a href="https://lk.medveddoc.com/login">
                  <v-icon small>{{ icons.mdiAccountCircle }}</v-icon>
                  Личный кабинет для клиник
                </a>
              </div>
            </v-col>
            <v-col cols="12">
              <nav-menu-component></nav-menu-component>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-container class="burger-menu">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="burger-menu__button"
                      v-bind="attrs"
                      v-on="on"
                      text
                    >
                      <v-icon large>{{ icons.mdiMenu }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="burger-menu__list">
                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <router-link to="/">
                          <div>Главная</div>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <router-link
                          to="/about"
                          class="d-flex justify-center align-center"
                        >
                          <span>О нас</span>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <router-link
                          to="/blog"
                          class="d-flex justify-center align-center"
                        >
                          <span>Новости</span>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <router-link to="/clinic-landing">
                          <div>Сотрудничество</div>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <router-link
                          to="/help"
                          class="d-flex justify-center align-center"
                        >
                          <span>Помощь</span>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <a
                          class="header__menu_item"
                          href="https://lk.medveddoc.com/login"
                          >Личный кабинет</a
                        >
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="header__menu_item">
                        <a @click="dialogCity">
                          <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                          {{ selectedCity.title }}
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <city-modal v-if="modalStatus" />
  </header>
</template>

<script>
import * as names from "../../store/names";
import { mapActions, mapGetters } from "vuex";
import NavMenuComponent from "./NavMenuComponent";
import {
  mdiMapMarker,
  mdiMenu,
  mdiAccountCircle,
  mdiInformation,
  mdiAccountSupervisorCircle,
} from "@mdi/js";
import CityModal from "components/partials/CityModal";



export default {
  components: { CityModal, NavMenuComponent },
  data: () => ({
    icons: {
      mdiMapMarker,
      mdiMenu,
      mdiAccountCircle,
      mdiInformation,
      mdiAccountSupervisorCircle,
    },
  }),
  computed: {
    ...mapGetters({
      modalStatus: names.CITY_MODAL_STATUS,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
  },
  methods: {
    ...mapActions({
      saveModalStatus: names.ACTION_SAVE_CITY_MODAL_STATUS,
    }),
    async dialogCity() {
      this.saveModalStatus(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.header {
  font-family: $montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  max-width: 930px;
  margin: 0 auto;

  &__logo {
    margin: 20px 0;
    max-width: 280px;
    line-height: 0.9;
    img {
      width: 256px;
      height: 40px;
      @include phone-only {
        width: 214px;
        height: auto;
      }
    }
    p {
      text-align: center;
      width: 256px;
      @include phone-only {
        text-align: left;
        padding-left: 10px;
        font-size: 0.7rem;
      }
    }
  }
}
.header__menu_item {
  display: inline-block;
  margin: 0 10px;
  color: $grey65;
  font-family: $sanspro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  a {
    @include link-light($grey65, $light-blue);
    text-decoration: none;
    .v-icon {
      color: inherit !important;
      padding-bottom: 5px;
    }

    &:focus {
      color: $light-blue;
    }
  }
}
.burger-menu {
  text-align: right;
  &__list {
    text-align: center;
    font-family: $sanspro, sans-serif;
    .header__menu_item {
      font-size: 15px;
    }
  }
  &__button {
    font-size: 26px !important;
  }
}
.color-light-blue {
  color: $grey-blue;
}
</style>
