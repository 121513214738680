/*Состояния*/
const state = () => ({
  /*Объект с результатами поиска*/
  searchObj: [],

  /*Объект с результатами поиска услуг*/
  searchServiceObj: [],

  searchServicePagination: [],

  /*Объект с результатами поиска клиник*/
  searchClinicObj: [],

  /*Объект с услугами по врачам или специализациям*/
  objServiceBy: [],
  /*Объект со специализацией врача для поиска(переделать)*/
  objSpecializationNames: [],

  /*Сортировка - BEGIN*/
  objPaginationSearch: [],
  objSortValuesSearch: [],
  selectedSortValue: "price_asc",
  selectedTypeValue: null,
  selectedCityParts: null,
  objTypeValuesSearch: [],
  selectedMyCoordinatesLat: null,
  selectedMyCoordinatesLong: null,
  myAddress: "",
  availableFilters: [],
  maxPriceSearch: null,
  minPriceSearch: null,
  selectedPrice: [],
  /*Сортировка - END*/

  /*Объект с подробным результатом услуг о доктора*/
  objDoctorDetail: [],

  /*Объект с краткой информацией о враче*/
  objDoctorDetailInfo: [],

  /*Объект с пагинацией в шторке с услугами*/
  objDoctorDetailPagination: [],

  /*Объект со списком врачей, оказывающих определенную услугу*/
  objServiceDetail: [],

  objServiceDetailPagination: [],

  /*Объект с информацией о клинике*/
  objClinicDetail: [],

  /*Поисковое значение*/
  searchValue: "",

  /*Тип поиска*/
  searchType: "doctor",

  /*Текст в инпуте*/
  searchPlaceholder: "Начните вводить ФИО врача или специальность",

  /*Сообщение о результатах выполнения запроса*/
  message: {},

  /*ID Филиала клиники*/
  departmentId: {},

  /* Информация для быстрой записи (Врач)*/
  selectedDoctorInfo: {},

  /* Информация для быстрой записи (Услуга) */
  selectedServiceInfo: {},
});
export { state };
