//Api address
export const API_ADDRESS = "https://old.medveddoc.com";

//Snackbar
export const MUTATE_SHOW_MESSAGE = "snackbar/MUTATE_SHOW_MESSAGE";

//Internet Connection
export const CONNECTED = "connection/CONNECTED";
export const MUTATE_SET_CONNECTED = "connection/MUTATE_SET_CONNECTED";
export const ACTION_SET_CONNECTED = "connection/ACTION_SET_CONNECTED";

/*Navigation Drawer*/
export const MUTATE_SHOW_DRAWER = "drawer/MUTATE_SHOW_DRAWER";
export const DRAWER_TYPE = "drawer/DRAWER_TYPE";
export const AFTER_DETAIL = "drawers/AFTER_DETAIL";
export const MUTATE_TOGGLE_DRAWER = "drawer/MUTATE_TOGGLE_DRAWER";
export const MUTATE_SAVE_AFTER_DETAIL = "drawer/MUTATE_SAVE_AFTER_DETAIL";

export const ACTION_SAVE_AFTER_DETAIL = "drawer/ACTION_SAVE_AFTER_DETAIL";

/*Preloader*/
export const MUTATE_SHOW_PRELOADER = "preloader/MUTATE_SHOW_PRELOADER";

/*News*/
/*Getters*/
export const NEWS_MESSAGE = "news/NEWS_MESSAGE";
export const OBJ_NEWS = "news/OBJ_NEWS";
export const OBJ_CURRENT_NEWS = "news/OBJ_CURRENT_NEWS";
export const OBJ_NEWS_PAGINATION = "news/OBJ_NEWS_PAGINATION";

/*Mutations*/
export const MUTATE_SAVE_OBJ_NEWS = "news/MUTATE_SAVE_OBJ_NEWS";
export const MUTATE_SAVE_OBJ_CURRENT_NEWS = "news/MUTATE_SAVE_OBJ_CURRENT_NEWS";
export const MUTATE_SAVE_NEWS_MESSAGE = "news/MUTATE_SAVE_NEWS_MESSAGE";
export const MUTATE_SAVE_OBJ_NEWS_PAGINATION =
  "news/MUTATE_SAVE_OBJ_NEWS_PAGINATION";

/*Actions*/
export const ACTION_GET_ALL_NEWS = "news/ACTION_GET_ALL_NEWS";
export const ACTION_GET_CURRENT_NEWS = "news/ACTION_GET_CURRENT_NEWS";

/*Pages*/
/*Getters*/
export const PAGE_MESSAGE = "pages/PAGE_MESSAGE";
export const OBJ_PAGE = "pages/OBJ_PAGE";

/*Mutations*/
export const MUTATE_SAVE_OBJ_PAGE = "pages/MUTATE_SAVE_OBJ_PAGE";
export const MUTATE_SAVE_PAGE_MESSAGE = "pages/MUTATE_SAVE_PAGE_MESSAGE";

/*Basket*/
/*Getters*/
export const BASKET_MESSAGE = "basket/BASKET_MESSAGE";
export const OBJ_BASKET = "basket/OBJ_BASKET";

/*Actions*/
export const ACTION_GET_BASKET = "basket/ACTION_GET_BASKET";
export const ACTION_ADD_BASKET = "basket/ACTION_ADD_BASKET";

/*Mutations*/
export const MUTATE_SAVE_OBJ_BASKET = "basket/MUTATE_SAVE_OBJ_BASKET";
export const MUTATE_SAVE_BASKET_MESSAGE = "basket/MUTATE_SAVE_BASKET_MESSAGE";
export const MUTATE_ADD_BASKET_MESSAGE = "basket/MUTATE_ADD_BASKET_MESSAGE";

/*Actions*/
export const ACTION_GET_PAGE = "pages/ACTION_GET_PAGE";

/*Search*/
/*Getters*/
export const SEARCH_MESSAGE = "search/SEARCH_MESSAGE";
export const OBJ_SEARCH = "search/OBJ_SEARCH";
export const OBJ_SEARCH_SERVICE = "search/OBJ_SEARCH_SERVICE";
export const OBJ_SEARCH_SERVICE_PAGINATION =
  "search/OBJ_SEARCH_SERVICE_PAGINATION";
export const OBJ_SEARCH_CLINIC = "search/OBJ_SEARCH_CLINIC";
export const OBJ_SEARCH_SERVICE_BY = "search/OBJ_SEARCH_SERVICE_BY";
export const OBJ_SEARCH_SERVICE_SPEC = "search/OBJ_SEARCH_SERVICE_SPEC";
export const OBJ_SEARCH_DOCTOR_DETAIL = "search/OBJ_SEARCH_DOCTOR_DETAIL";
export const OBJ_SEARCH_DOCTOR_DETAIL_INFO =
  "search/OBJ_SEARCH_DOCTOR_DETAIL_INFO";
export const OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION =
  "search/OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION";

export const OBJ_SEARCH_SERVICE_DETAIL = "search/OBJ_SEARCH_SERVICE_DETAIL";
export const OBJ_SEARCH_SERVICE_DETAIL_PAGINATION =
  "search/OBJ_SEARCH_SERVICE_DETAIL_PAGINATION";

export const OBJ_SEARCH_CLINIC_DETAIL = "search/OBJ_SEARCH_CLINIC_DETAIL";
export const SEARCH_TYPE = "search/SEARCH_TYPE";
export const SEARCH_VALUE = "search/SEARCH_VALUE";
export const SEARCH_PLACEHOLDER = "search/SEARCH_PLACEHOLDER";
export const DEPARTMENT_ID = "search/DEPARTMENT_ID";

export const SELECTED_DOCTOR_INFO = "search/SELECTED_DOCTOR_INFO";
export const SELECTED_SERVICE_INFO = "search/SELECTED_SERVICE_INFO";
export const OBJ_SEARCH_PAGINATION = "search/OBJ_SEARCH_PAGINATION";
export const OBJ_SEARCH_SORT_VALUES = "search/OBJ_SEARCH_SORT_VALUES";
export const SELECTED_SORT_VALUE = "search/SELECTED_SORT_VALUE";
export const SELECTED_CITY_PARTS = "search/SELECTED_CITY_PARTS";
export const SELECTED_TYPE_VALUE = "search/SELECTED_TYPE_VALUE";
export const SELECTED_PRICE_VALUE = "search/SELECTED_PRICE_VALUE";
export const SELECTED_MY_COORDINATES_LAT = "search/SELECTED_MY_COORDINATES_LAT";
export const SELECTED_MY_COORDINATES_LONG = "search/SELECTED_MY_COORDINATES_LONG";
export const SELECTED_MY_ADDRESS = "search/SELECTED_MY_ADDRESS";
export const AVAILABLE_FILTERS = "search/AVAILABLE_FILTERS";
export const OBJ_SEARCH_TYPE_VALUES = "search/OBJ_SEARCH_TYPE_VALUES";
export const MAX_PRICE_SEARCH = "search/MAX_PRICE_SEARCH";
export const MIN_PRICE_SEARCH = "search/MIN_PRICE_SEARCH";

/*Mutations*/
export const MUTATE_SAVE_OBJ_SEARCH = "search/MUTATE_SAVE_OBJ_SEARCH";
export const MUTATE_CLEAR_OBJ_SEARCH = "search/MUTATE_CLEAR_OBJ_SEARCH";
export const MUTATE_SAVE_OBJ_SERVICE_BY = "search/MUTATE_SAVE_OBJ_SERVICE_BY";
export const MUTATE_CLEAR_OBJ_SERVICE_BY = "search/MUTATE_CLEAR_OBJ_SERVICE_BY";
export const MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL =
  "search/MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL";
export const MUTATE_CLEAR_OBJ_SEARCH_DOCTOR_DETAIL =
  "search/MUTATE_CLEAR_OBJ_SEARCH_DOCTOR_DETAIL";
export const MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION =
  "search/MUTATE_SAVE_OBJ_SEARCH_DOCTOR_PAGINATION";
export const MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL =
  "search/MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL";
export const MUTATE_SAVE_OBJ_SEARCH_SERVICE =
  "search/MUTATE_SAVE_OBJ_SEARCH_SERVICE";
export const MUTATE_SAVE_OBJ_SEARCH_SERVICE_PAGINATION =
  "search/MUTATE_SAVE_OBJ_SEARCH_SERVICE_PAGINATION";
export const MUTATE_CLEAR_OBJ_SEARCH_SERVICE_DETAIL =
  "search/MUTATE_CLEAR_OBJ_SEARCH_SERVICE_DETAIL";
export const MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL_PAGINATION =
  "search/MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL_PAGINATION";

export const MUTATE_SAVE_OBJ_SEARCH_CLINIC_DETAIL =
  "search/MUTATE_SAVE_OBJ_SEARCH_CLINIC_DETAIL";
export const MUTATE_CLEAR_OBJ_SEARCH_CLINIC_DETAIL =
  "search/MUTATE_CLEAR_OBJ_SEARCH_CLINIC_DETAIL";
export const MUTATE_SAVE_OBJ_SEARCH_CLINIC =
  "search/MUTATE_SAVE_OBJ_SEARCH_CLINIC";
export const MUTATE_SAVE_SEARCH_MESSAGE = "search/MUTATE_SAVE_SEARCH_MESSAGE";
export const MUTATE_TRANSMISSION_SEARCH_VALUE =
  "search/MUTATE_TRANSMISSION_SEARCH_VALUE";
export const MUTATE_TRANSMISSION_SEARCH_TYPE =
  "search/MUTATE_TRANSMISSION_SEARCH_TYPE";
export const MUTATE_TRANSMISSION_SEARCH_PLACEHOLDER =
  "search/MUTATE_TRANSMISSION_SEARCH_PLACEHOLDER";
export const MUTATE_SAVE_DEPARTMENT_ID = "search/MUTATE_SAVE_DEPARTMENT_ID";

export const MUTATE_SAVE_SELECTED_DOCTOR_INFO =
  "search/MUTATE_SAVE_SELECTED_DOCTOR_INFO";
export const MUTATE_SAVE_SELECTED_SERVICE_INFO =
  "search/MUTATE_SAVE_SELECTED_SERVICE_INFO";
export const MUTATE_SAVE_SELECTED_SORT_VALUES =
  "search/MUTATE_SAVE_SELECTED_SORT_VALUES";

/*Actions*/
export const ACTION_MAIN_SEARCH = "search/ACTION_MAIN_SEARCH";
export const ACTION_CLEAR_MAIN_SEARCH = "search/ACTION_CLEAR_MAIN_SEARCH";
export const ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_FIRST_SERVICE_BY_DOCTOR_ID =
  "search/ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_FIRST_SERVICE_BY_DOCTOR_ID";
export const ACTION_CLEAR_OBJ_SERVICE_BY = "search/ACTION_CLEAR_OBJ_SERVICE_BY";
export const ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_SERVICE_BY_SPECIALIZATION_ID =
  "search/ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_SERVICE_BY_SPECIALIZATION_ID";
export const ACTION_MAIN_SEARCH_DOCTOR_DETAIL =
  "search/ACTION_MAIN_SEARCH_DOCTOR_DETAIL";
export const ACTION_MAIN_SEARCH_DOCTOR_DETAIL_CLEAR =
  "search/ACTION_MAIN_SEARCH_DOCTOR_DETAIL_CLEAR";
export const ACTION_MAIN_SEARCH_SERVICE = "search/ACTION_MAIN_SEARCH_SERVICE";
export const ACTION_MAIN_SEARCH_SERVICE_DETAIL =
  "search/ACTION_MAIN_SEARCH_SERVICE_DETAIL";

export const ACTION_MAIN_SEARCH_SERVICE_DETAIL_CLEAR =
  "search/ACTION_MAIN_SEARCH_SERVICE_DETAIL_CLEAR";

export const ACTION_MAIN_SEARCH_CLINIC = "search/ACTION_MAIN_SEARCH_CLINIC";
export const ACTION_MAIN_SEARCH_CLINIC_DETAIL =
  "search/ACTION_MAIN_SEARCH_CLINIC_DETAIL";
export const ACTION_MAIN_SEARCH_CLINIC_DETAIL_CLEAR =
  "search/ACTION_MAIN_SEARCH_CLINIC_DETAIL_CLEAR";
export const ACTION_TRANSMISSION_SEARCH_VALUE =
  "search/ACTION_TRANSMISSION_SEARCH_VALUE";
export const ACTION_TRANSMISSION_SEARCH_TYPE =
  "search/ACTION_TRANSMISSION_SEARCH_TYPE";
export const ACTION_TRANSMISSION_SEARCH_PLACEHOLDER =
  "search/ACTION_TRANSMISSION_SEARCH_PLACEHOLDER";
export const ACTION_CLEAR_SEARCH_OBJ = "search/ACTION_CLEAR_SEARCH_OBJ";
export const ACTION_SAVE_DEPARTMENT_ID = "search/ACTION_SAVE_DEPARTMENT_ID";

export const ACTION_SAVE_SELECTED_DOCTOR_INFO =
  "search/ACTION_SAVE_SELECTED_DOCTOR_INFO";
export const ACTION_SAVE_SELECTED_SERVICE_INFO =
  "search/ACTION_SAVE_SELECTED_SERVICE_INFO";
export const ACTION_SAVE_SELECTED_SORT_VALUES =
  "search/ACTION_SAVE_SELECTED_SORT_VALUES";

/*Order*/
/*Getters*/
export const ORDER_SUCCESS = "order/ORDER_SUCCESS";
export const ORDER_MESSAGE = "order/ORDER_MESSAGE";
export const ORDER_MODAL_ACTIVE = "order/ORDER_MODAL_ACTIVE";

/*Mutations*/
export const MUTATE_SAVE_ORDER_SUCCESS = "order/MUTATE_SAVE_ORDER_SUCCESS";
export const MUTATE_SAVE_MODAL_ACTIVE = "order/MUTATE_SAVE_MODAL_ACTIVE";
export const MUTATE_SAVE_ORDER_MESSAGE = "order/MUTATE_SAVE_ORDER_MESSAGE";

/*Actions*/
export const ACTION_SAVE_MODAL_ACTIVE = "order/ACTION_SAVE_MODAL_ACTIVE";
export const ACTION_FAST_ORDER = "order/ACTION_FAST_ORDER";

/*Template Loading*/
/*Getters*/
export const RESULT_TEMPLATE_NAME = "template/RESULT_TEMPLATE_NAME";
export const SEARCH_DATA = "template/SEARCH_DATA";
export const ENTITY_NAME = "template/ENTITY_NAME";
export const ENTITY_ID = "template/ENTITY_ID";

/*Mutations*/
export const MUTATE_SAVE_RESULT_TEMPLATE_NAME =
  "template/MUTATE_SAVE_RESULT_TEMPLATE_NAME";
export const MUTATE_SAVE_ENTITY_NAME = "template/MUTATE_SAVE_ENTITY_NAME";
export const MUTATE_SAVE_ENTITY_ID = "template/MUTATE_SAVE_ENTITY_ID";
export const MUTATE_SELECT_RESULT_TEMPLATE =
  "template/MUTATE_SELECT_RESULT_TEMPLATE";

/*Actions*/
export const ACTION_TRANSMISSION_RESULT_TEMPLATE_NAME =
  "template/ACTION_TRANSMISSION_RESULT_TEMPLATE_NAME";
export const ACTION_TRANSMISSION_ENTITY_NAME =
  "template/ACTION_TRANSMISSION_ENTITY_NAME";
export const ACTION_TRANSMISSION_ENTITY_ID =
  "template/ACTION_TRANSMISSION_ENTITY_ID";
export const ACTION_SELECT_RESULT_TEMPLATE =
  "template/ACTION_SELECT_RESULT_TEMPLATE";
export const ACTION_CLEAR_RESULT_TEMPLATE =
  "template/ACTION_CLEAR_RESULT_TEMPLATE";

/*City*/
/*Getters*/
export const CITY_MESSAGE = "city/CITY_MESSAGE";
export const CITY_MODAL_STATUS = "city/CITY_MODAL_STATUS";
export const OBJ_CITIES = "city/OBJ_CITIES";
export const OBJ_CITY = "city/OBJ_CITY";
export const OBJ_SELECTED_CITY = "city/OBJ_SELECTED_CITY";
export const OBJ_CITY_PARTS = "city/OBJ_CITY_PARTS";
export const OBJ_CITY_COUNT = "city/OBJ_CITY_COUNT";
export const OBJ_CITY_NAMES = "city/OBJ_CITY_NAMES";
export const OBJ_POPULAR_SPECIALIZATION = "city/OBJ_POPULAR_SPECIALIZATION";

/*Mutations*/
export const MUTATE_SAVE_OBJ_CITIES = "city/MUTATE_SAVE_OBJ_CITIES";
export const MUTATE_SAVE_OBJ_CITY = "city/MUTATE_SAVE_OBJ_CITY";
export const MUTATE_SAVE_CITY_PARTS = "city/MUTATE_SAVE_CITY_PARTS";
export const MUTATE_SAVE_CITY_COUNT = "city/MUTATE_SAVE_CITY_COUNT";
export const MUTATE_SAVE_POPULAR_SPECIALIZATION =
  "city/MUTATE_SAVE_POPULAR_SPECIALIZATION";
export const MUTATE_SAVE_CITY_MESSAGE = "city/MUTATE_SAVE_CITY_MESSAGE";
export const MUTATE_SAVE_CITY_MODAL_STATUS =
  "city/MUTATE_SAVE_CITY_MODAL_STATUS";
export const MUTATE_SAVE_SELECTED_CITY = "city/MUTATE_SAVE_SELECTED_CITY";

/*Actions*/
export const ACTION_GET_CITIES = "city/ACTION_GET_CITIES";
export const ACTION_GET_CITY_PARTS = "city/ACTION_GET_CITY_PARTS";
export const ACTION_GET_CITY_COUNT = "city/ACTION_GET_CITY_COUNT";
export const ACTION_SAVE_SELECTED_CITY = "city/ACTION_SAVE_SELECTED_CITY";
export const ACTION_GET_POPULAR_SPECIALIZATION =
  "city/ACTION_GET_POPULAR_SPECIALIZATION";
export const ACTION_SAVE_CITY_MODAL_STATUS =
  "city/ACTION_SAVE_CITY_MODAL_STATUS";
export const ACTION_GET_CITY_BY_COORDINATES =
  "city/ACTION_GET_CITY_BY_COORDINATES";

/* Doctor card*/
/*Getters*/
export const DOCTOR_ID = "doctorCard/DOCTOR_ID";
export const OBJ_DOCTOR_CARD = "doctorCard/OBJ_DOCTOR_CARD";
export const OBJ_DOCTOR_CARD_SERVICES = "doctorCard/OBJ_DOCTOR_CARD_SERVICES";
export const OBJ_DOCTOR_CARD_SPECIALIZATION =
  "doctorCard/OBJ_DOCTOR_CARD_SPECIALIZATION";
export const OBJ_DOCTOR_CARD_DEPARTMENT = "doctorCard/OBJ_DOCTOR_CARD_CLINIC";
export const OBJ_DOCTOR_CARD_SERVICE_PAGINATION =
  "doctorCard/OBJ_DOCTOR_CARD_SERVICE_PAGINATION";

/*Mutations*/
export const MUTATE_SAVE_DOCTOR_ID = "doctorCard/MUTATE_SAVE_DOCTOR_ID";
export const MUTATE_SAVE_OBJ_DOCTOR_CARD =
  "doctorCard/MUTATE_SAVE_OBJ_DOCTOR_CARD";
export const MUTATE_SAVE_OBJ_DOCTOR_SERVICES =
  "doctorCard/MUTATE_SAVE_OBJ_DOCTOR_SERVICES";
export const MUTATE_SAVE_DOCTOR_CARD_MESSAGE =
  "doctorCard/MUTATE_SAVE_DOCTOR_CARD_MESSAGE";
export const MUTATE_SAVE_DOCTOR_CARD_SERVICE_PAGINATION =
  "doctorCard/MUTATE_SAVE_DOCTOR_CARD_SERVICE_PAGINATION";

/*Actions*/
export const ACTION_SAVE_DOCTOR_ID = "doctorCard/ACTION_SAVE_DOCTOR_ID";
export const ACTION_SAVE_OBJ_DOCTOR_CARD =
  "doctorCard/ACTION_SAVE_OBJ_DOCTOR_CARD";
export const ACTION_GET_DOCTOR_SERVICES =
  "doctorCard/ACTION_GET_DOCTOR_SERVICES";

/*Department card*/
/*Getters*/
export const DEPARTMENT_ID_CARD = "departmentCard/DEPARTMENT_ID_CARD";
export const OBJ_CLINIC_DEPARTMENTS = "departmentCard/OBJ_CLINIC_DEPARTMENTS";

export const OBJ_DEPARTMENT_INFO = "departmentCard/OBJ_DEPARTMENT_INFO";

export const DEPARTMENT_SELECTED_DOCTOR_INFO =
  "departmentCard/DEPARTMENT_SELECTED_DOCTOR_INFO";

export const DEPARTMENT_SELECTED_DOCTOR_SERVICES =
  "departmentCard/DEPARTMENT_SELECTED_DOCTOR_SERVICES";

export const DEPARTMENT_GALLERY = "departmentCard/DEPARTMENT_GALLERY";

export const DEPARTMENT_SERVICES = "departmentCard/DEPARTMENT_SERVICES";

export const DEPARTMENT_DOCTORS = "departmentCard/DEPARTMENT_DOCTORS";

export const DEPARTMENT_SERVICES_SPECIALIZATIONS =
  "departmentCard/DEPARTMENT_SERVICES_SPECIALIZATIONS";

export const DEPARTMENT_DOCTORS_SPECIALIZATIONS =
  "departmentCard/DEPARTMENT_DOCTORS_SPECIALIZATIONS";

export const OBJ_DEPARTMENT_SERVICE_PAGINATION =
    "departmentCard/OBJ_DEPARTMENT_SERVICE_PAGINATION";

/*Mutations*/
export const MUTATE_SAVE_DEPARTMENT_ID_CARD =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_ID_CARD";

export const MUTATE_SAVE_OBJ_CLINIC_DEPARTMENTS =
  "departmentCard/MUTATE_SAVE_OBJ_CLINIC_DEPARTMENTS";

export const MUTATE_SAVE_DEPARTMENT_GALLERY =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_GALLERY";

export const MUTATE_SAVE_DEPARTMENT_NAME =
    "departmentCard/MUTATE_SAVE_DEPARTMENT_NAME";

export const MUTATE_SAVE_DEPARTMENT_INFO =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_INFO";

export const MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE";

export const MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO";

export const MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES =
  "departmentCard/MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES";

export const MUTATE_ClEAR_DEPARTMENT_DRAWERS_INFO =
  "departmentCard/MUTATE_ClEAR_DEPARTMENT_DRAWERS_INFO";

export const MUTATE_GET_DEPARTMENT_SERVICES =
  "departmentCard/MUTATE_GET_DEPARTMENT_SERVICES";

export const MUTATE_GET_DEPARTMENT_DOCTORS =
  "departmentCard/MUTATE_GET_DEPARTMENT_DOCTORS";

export const MUTATE_GET_DEPARTMENT_DOCTORS_SPECIALIZATION =
  "departmentCard/MUTATE_GET_DEPARTMENT_DOCTORS_SPECIALIZATION";

export const MUTATE_SAVE_DEPARTMENT_SERVICE_PAGINATION =
   "departmentCard/MUTATE_SAVE_DEPARTMENT_SERVICE_PAGINATION";

/*Actions*/
export const ACTION_SAVE_DEPARTMENT_ID_CARD =
  "departmentCard/ACTION_SAVE_DEPARTMENT_ID_CARD";

export const ACTION_SAVE_OBJ_CLINIC_DEPARTMENTS =
  "departmentCard/ACTION_SAVE_OBJ_CLINIC_DEPARTMENTS";

export const ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO =
  "departmentCard/ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO";

export const ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES =
  "departmentCard/ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES";

export const ACTION_ClEAR_DEPARTMENT_DRAWERS_INFO =
  "departmentCard/ACTION_ClEAR_DEPARTMENT_DRAWERS_INFO";

export const ACTION_GET_DEPARTMENT_GALLERY =
  "departmentCard/ACTION_SAVE_DEPARTMENT_GALLERY";

export const ACTION_GET_DEPARTMENT_NAME =
    "departmentCard/ACTION_SAVE_DEPARTMENT_NAME";

export const ACTION_GET_DEPARTMENT_SERVICES =
  "departmentCard/ACTION_GET_DEPARTMENT_SERVICES";

export const ACTION_GET_DEPARTMENT_DOCTORS =
  "departmentCard/ACTION_GET_DEPARTMENT_DOCTORS";

export const ACTION_GET_DEPARTMENT_DOCTORS_SPECIALIZATIONS =
  "departmentCard/ ACTION_GET_DEPARTMENT_DOCTORS_SPECIALIZATIONS";

/*Feedback*/
/*Getters*/
export const OBJ_FEEDBACK = "feedback/OBJ_FEEDBACK";


/*Mutations*/
export const MUTATE_SAVE_OBJ_FEEDBACK = "feedback/MUTATE_SAVE_OBJ_FEEDBACK";


export const MUTATE_SAVE_FEEDBACK_MESSAGE =
  "feedback/MUTATE_SAVE_FEEDBACK_MESSAGE";

/*Actions*/
export const ACTION_GET_OBJ_FEEDBACK = "feedback/ACTION_SAVE_OBJ_FEEDBACK";
export const ACTION_SEND_OBJ_FEEDBACK = "feedback/ACTION_SEND_OBJ_FEEDBACK";

/*Map*/
/*Getters*/
export const ADDRESS_DIALOG = "map/ADDRESS_DIALOG";
export const MAP_ADDRESS_DIALOG = "map/MAP_ADDRESS_DIALOG";
export const MY_COORDINATES_LAT = "map/MY_COORDINATES_LAT";
export const MY_COORDINATES_LONG = "map/MY_COORDINATES_LONG";
export const MY_ADDRESS = "map/MY_ADDRESS";

/*Mutations*/
export const MUTATE_SAVE_ADDRESS_DIALOG = "map/MUTATE_SAVE_ADDRESS_DIALOG";
export const MUTATE_SAVE_MAP_ADDRESS_DIALOG = "map/MUTATE_SAVE_MAP_ADDRESS_DIALOG";
export const MUTATE_SAVE_MAP_COORDINATES = "map/MUTATE_SAVE_MAP_COORDINATES";

/*Actions*/
export const ACTION_SAVE_ADDRESS_DIALOG = "map/ACTION_SAVE_ADDRESS_DIALOG";
export const ACTION_SAVE_MAP_ADDRESS_DIALOG = "map/ACTION_SAVE_MAP_ADDRESS_DIALOG";
export const ACTION_SAVE_MAP_COORDINATES = "map/ACTION_SAVE_MAP_COORDINATES";
